<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="pb-3" outlined>
                    <v-container fluid>
                        <v-row>
                            <!-- <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-account-box"
                                    solo
                                    dense
                                    v-model="customer"
                                    :items="customers"
                                    item-value="value"
                                    item-text="label"
                                    label="Customer Category"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col> -->
                            <v-col class="col-6" sm="6" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="date_from"
                                        label="Start Periode"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        type="month"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" >
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="date_to"
                                        label="End Periode"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        type="month"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-select-group"
                                    clearable
                                    solo
                                    dense
                                    v-model="commodity"
                                    :items="commodities"
                                    item-value="commodity_id"
                                    item-text="descr"
                                    label="Commodity"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => [getThick(event), getWidth(event)]"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-watermark"
                                    clearable
                                    solo
                                    dense
                                    v-model="brand"
                                    :items="brands"
                                    item-value="brand_id"
                                    item-text="descr"
                                    label="Brand"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" lg="1">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-up-down"
                                    clearable
                                    solo
                                    dense
                                    v-model="thick"
                                    :items="thickness"
                                    item-value="thick"
                                    :item-text="item => $store.getters.convertToCurrency(item.thick)"
                                    label="Thickness"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                                
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" lg="1">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-left-right"
                                    clearable
                                    solo
                                    dense
                                    v-model="width"
                                    :items="widths"
                                    item-value="width"
                                    :item-text="item => $store.getters.convertToCurrency(item.width)"
                                    label="Width"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="border-12"  style="padding: 20px;"
                                color="info" elevation="2" small @click="submit()">Search</v-btn>
                            </v-col>
                        </v-row>
                        
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="12">
                <v-card id="sumScShipmentAnalysis" outlined height="420">
                    <v-card-text>
                        <h6 class="indigo-text m-0">Query Result: </h6>
                        <v-img
                        class="text-center mt-5 mx-auto"
                        lazy-src="@/assets/empty_data.png"
                        max-width="300"
                        src="@/assets/empty_data.png"
                        v-if="showData"
                        ></v-img>
                        <div id="container1" style="height: 370px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-5 mb-12">
            <v-col cols="12" xs="12" 
            sm="6" 
            md="3">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container2" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="3">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container3" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="3">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container4" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="3">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container5" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "@/backend-api/backend-api-sr"
import _ from "lodash"

export default {
    data() {
        return {
            selected: ['SC', 'FGPROD'],
            breadcumbs: [
                {
                text: 'Sunrise Mill',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Sales Marketing Analysis',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'SC & FG Production Analysis',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            loading: false,
            saless: [],
            sales: '',
            customers: [
                {
                    label: 'All',
                    value: 'All'
                },
                {
                    label: 'KKA',
                    value: 'KKA'
                },
                {
                    label: 'Non KKA',
                    value: 'Non KKA'
                }
            ],
            customer: '',
            search: null,
            order_id: '',
            loadingOrder: false,
            items:[],
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false, 
            brands: [],
            brand: '',
            coat_masss: [],
            coat_mass: '',
            grades: [],
            grade: '',
            thickness: [],
            thick: '',
            widths: [],
            width: '',
            colours: [],
            colour: '',
            qualities: [],
            commodity: '',
            commodities: [],
            quality: '',     
            showData: true,
            showDataDetail: true      
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        this.customer = 'All'
        await this.getBrand()
        await this.getCommodity()
        // await this.getCoatMass()
        // await this.getGrade()
        // await this.getThick()
        // await this.getWidth()
        // await this.getColour()
        // await this.getQuality()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        close(){
            this.dialog = false
        },
        async getBrand(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/brand`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.brands = res.data
            });
        },
        async getCommodity(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/commodity`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.commodities = res.data
            });
        },
        async getCoatMass(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/coat_mass`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.coat_masss = res.data
            });
        },
        async getGrade(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/grade`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.grades = res.data
            });
        },
        async getThick(event){
            if (event == '' || event == null) {
                this.thick = ''
            } else {
                await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/thicks?commodity_id=${this.commodity ? this.commodity : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.thickness = res.data
                });
            }
            
        },
        async getWidth(event){
            if (event == '' || event == null) {
                this.width = ''
            } else {
                await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/width?commodity_id=${this.commodity ? this.commodity : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.widths = res.data
                });
            }
        },
        async getColour(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/colour?brand=${this.brand ? this.brand : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.colours = res.data
            });
        },
        async getQuality(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sm/quality?commodity_id=GLV`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.qualities = res.data
            });
        },
        checkSelected(){
            if (this.selected.length > 2) {
                this.selected = []
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please max check 2 item",
                    visible: true
                };
            }
        },
        async submit(){
            this.$store.dispatch('setOverlay', true)

            if (this.selected.length < 2 || this.date_from == '') {
                this.$store.dispatch('setOverlay', false)

                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please fill start Period",
                    visible: true
                };
                return false
            }

            var start = this.date_from ? new Date(this.date_from).toISOString().substr(0, 7).replace(/-/g,"") : ''
            var end = this.date_to ? new Date(this.date_to).toISOString().substr(0, 7).replace(/-/g,"") : ''

            var reqBody = {
                'customer': this.customer ? this.customer : '',
                'start': start,
                'end': end,
                'brand': this.brand ? this.brand : '',
                'commodity_id': this.commodity ? this.commodity : '',
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
            }

            var dt1 = [];
            var dt2 = [];
            var dt3 = [];

            var data1 = {}
            var data2 = {}
            var data3 = {}

            var label = ''
            var container = 'container1';
            var title = " Summary";

            const respData = await backendApi.fetchCore('/v2/sm/analysis/sc-prod', reqBody, false, false, false)
            if (respData.status === 200) {
                label = 'SC & ';

                for (var i = 0; i < respData.data.length; i++) {
                    dt1.push({ label: respData.data[i].periode , y: parseFloat(respData.data[i].total_wgt)});
                }

                data1 = {
                    type: "column",
                    click: this.onClick1,
                    name: "Total SC",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    // indexLabelFontColor: "#FFF",
                    showInLegend: true,
                    indexLabelPlacement: "outside",  
                    indexLabelOrientation: "horizontal",
                    color: "#70ad47",
                    // yValueFormatString: "#,###,,,.##",
                }

                
            }

            const respDataFGPROD = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/indexProd', reqBody, false, false, false)
            if (respDataFGPROD.status === 200) {
                label = label + ' FG Production';

                for (var i = 0; i < respDataFGPROD.data.length; i++) {
                    dt2.push({ label: respDataFGPROD.data[i].periode , y: parseFloat(respDataFGPROD.data[i].total_wgt)});
                    dt3.push({ label: respDataFGPROD.data[i].periode , y: parseFloat(respDataFGPROD.data[i].total_wgt_awal)});
                }

                data2 = {
                    type: "column",
                    click: this.onClick2,
                    name: "Total FG Production",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    // indexLabelFontColor: "#FFF",
                    showInLegend: true,
                    indexLabelPlacement: "outside",  
                    indexLabelOrientation: "horizontal",
                    color: "#fb9b05",
                    // yValueFormatString: "#,###,,,.##",
                }

                data3 = {
                    type: "column",
                    click: this.onClick3,
                    name: "Total FG Prod Wgt Awal",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    // indexLabelFontColor: "#FFF",
                    showInLegend: true,
                    indexLabelPlacement: "outside",  
                    indexLabelOrientation: "horizontal",
                    color: "#a5a5a5",
                    // yValueFormatString: "#,###,,,.##",
                }
                console.log('data 1', dt1)
                console.log('data 2', dt2)
                console.log('data 3', dt3)
                
            }

            dt1 = this.fillMissingLabelsByYear(dt1, start, end)
            dt2 = this.fillMissingLabelsByYear(dt2, start, end)
            dt3 = this.fillMissingLabelsByYear(dt3, start, end)
            
            this.getChart(dt1, dt2, container, title, label, data1, data2, dt3, data3);

            await this.getPieChart([], 'container2', '', '', true)
            await this.getPieChart([], 'container3', '', '', true)
            await this.getPieChart([], 'container4', '', '', true)
            await this.getPieChart([], 'container5', '', '', true)
            this.showDataDetail = true

            $('html, body').animate({
                scrollTop: $("#sumScShipmentAnalysis").offset().top
            }, 1200)

            this.showData = false

            this.$store.dispatch('setOverlay', false)

        },
        generateLabels(startLabel, endLabel) {
            const labels = [];
            let [startYear, startMonth] = [parseInt(startLabel.substring(0, 4), 10), parseInt(startLabel.substring(4, 6), 10)];
            const [endYear, endMonth] = [parseInt(endLabel.substring(0, 4), 10), parseInt(endLabel.substring(4, 6), 10)];

            while (startYear < endYear || (startYear === endYear && startMonth <= endMonth)) {
                const label = `${startYear}${startMonth.toString().padStart(2, '0')}`;
                labels.push(label);

                startMonth++;
                if (startMonth > 12) {
                    startMonth = 1;
                    startYear++;
                }
            }

            return labels;
        },
        fillMissingLabelsByYear(data, startLabel, endLabel) {
            // _.forEach(data, (dataset, key) => {
                // Extract year from the dataset key
                // const datasetYear = parseInt(key.split(" ")[1], 10);

                // Generate labels for the past 12 months
                const labels = this.generateLabels(startLabel, endLabel);
                
                // Get existing labels in the dataset
                const existingLabels = _.map(data, 'label');
                
                // Determine which labels are missing
                const missingLabels = _.difference(labels, existingLabels);

                // Add missing labels to the dataset with a value of null
                _.forEach(missingLabels, (label) => {
                    data.push({ label: label, y: 0 });
                });

                // Sort dataset by label to maintain chronological order
                data.sort((a, b) => a.label.localeCompare(b.label));
            // });

            return data;
        },
        getChart(dt1, dt2, container, title, label, data1, data2, dt3, data3){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                axisY: {
                    // scaleBreaks: {
                    //     autoCalculate: true,
                    //     type: "wavy"
                    // },
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "TON",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    // cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    fontSize: 11
                },
                data: [ data1, data2, data3 ]
            });

            chart.options.data[0].dataPoints = dt1;
            chart.options.data[1].dataPoints = dt2;
            chart.options.data[2].dataPoints = dt3;
            this.showDefaultText(chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");
            if (x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                e.dataPoint.y = e.dataPoint.actualYValue;
                e.dataPoint.actualYValue = null;
                e.dataPoint.indexLabelFontSize = null;
                e.dataPoint.indexLabelLineThickness = null;
                e.dataPoint.legendMarkerType = "circle";
            } 
            else {
                e.dataPoint.actualYValue = e.dataPoint.y;
                e.dataPoint.y = 0;
                e.dataPoint.indexLabelFontSize = 0;
                e.dataPoint.indexLabelLineThickness = 0; 
                e.dataPoint.legendMarkerType = "cross";
            }
                e.chart.render();
        },
        showDefaultText(chart, text) {
            var dataPoints = chart.options.data[0].dataPoints;
            var isEmpty = !(dataPoints && dataPoints.length > 0);

            if (!isEmpty) {
                for (var i = 0; i < dataPoints.length; i++) {
                isEmpty = !dataPoints[i].y;
                if (!isEmpty)
                    break;
                }
            }

            if (!chart.options.subtitles)
                chart.options.subtitles = [];
            if (isEmpty) {
                chart.options.subtitles.push({
                text: text,
                verticalAlign: 'center',
                });
                chart.options.data[0].showInLegend = false;
            } else {
                chart.options.data[0].showInLegend = true;
            }
        },
        async onClick1(e){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'periode': e.dataPoint.label,
                'tahun': e.dataPoint.label.substr(0, 4),
                'bulan': e.dataPoint.label.substr(4, 6),
                'brand': this.brand ? this.brand : '',
                'commodity_id': this.commodity ? this.commodity : '',
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
            }

            const respData = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/getScShipmentAnalysisDetailbySC', reqBody, false, false, false)

            if (respData.status === 200) {
                console.log(respData.data);
                var param = e.dataPoint.label;
                var label_detail = "Periode: "+param;
                this.showDataDetail = false

                for (var i = 0; i < respData.data.qWidth.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qWidth.length; i++) {

                        dt_detail.push({  label: respData.data.qWidth[i].width,  y: parseInt(respData.data.qWidth[i].total_wgt), legendText: respData.data.qWidth[i].width });

                    }
                    var container_detail = 'container2';
                    var title_detail = 'by Width SC'
                    await this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qThick.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qThick.length; i++) {

                        dt_detail.push({  label: respData.data.qThick[i].thick,  y: parseInt(respData.data.qThick[i].total_wgt), legendText: respData.data.qThick[i].thick });

                    }
                    var container_detail = 'container3';
                    var title_detail = 'by Thickness SC'
                    await this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qBrand.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qBrand.length; i++) {

                        dt_detail.push({  label: respData.data.qBrand[i].brand_name,  y: parseInt(respData.data.qBrand[i].total_wgt), legendText: respData.data.qBrand[i].brand_name });

                    }
                    var container_detail = 'container4';
                    var title_detail = 'by Brand'
                    await this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qCommodity.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qCommodity.length; i++) {

                        dt_detail.push({  label: respData.data.qCommodity[i].commodity_id,  y: parseInt(respData.data.qCommodity[i].total_wgt), legendText: respData.data.qCommodity[i].commodity_id });

                    }
                    var container_detail = 'container5';
                    var title_detail = 'by Commodity'
                    await this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                this.$store.dispatch('setOverlay', false)
            }
        },
        async onClick2(e){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'periode': e.dataPoint.label,
                'tahun': e.dataPoint.label.substr(0, 4),
                'bulan': e.dataPoint.label.substr(4, 6),
                'brand': this.brand ? this.brand : '',
                'commodity_id': this.commodity ? this.commodity : '',
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
            }

            const respData = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/getScShipmentAnalysisDetailbyProd', reqBody, false, false, false)

            if (respData.status === 200) {
                var param = e.dataPoint.label;
                var label_detail = "Periode: "+param;
                this.showDataDetail = false

                for (var i = 0; i < respData.data.qWidth.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qWidth.length; i++) {

                        dt_detail.push({  label: respData.data.qWidth[i].width,  y: parseInt(respData.data.qWidth[i].total_wgt), legendText: respData.data.qWidth[i].width });

                    }
                    var container_detail = 'container2';
                    var title_detail = 'by Width FG Prod'
                    await this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qThick.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qThick.length; i++) {

                        dt_detail.push({  label: respData.data.qThick[i].thick,  y: parseInt(respData.data.qThick[i].total_wgt), legendText: respData.data.qThick[i].thick });

                    }
                    var container_detail = 'container3';
                    var title_detail = 'by Thickness FG Prod'
                    await this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qBrand.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qBrand.length; i++) {

                        dt_detail.push({  label: respData.data.qBrand[i].brand_name,  y: parseInt(respData.data.qBrand[i].total_wgt), legendText: respData.data.qBrand[i].brand_name });

                    }
                    var container_detail = 'container4';
                    var title_detail = 'by Brand FG Prod'
                    await this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qCommodity.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qCommodity.length; i++) {

                        dt_detail.push({  label: respData.data.qCommodity[i].commodity_id,  y: parseInt(respData.data.qCommodity[i].total_wgt), legendText: respData.data.qCommodity[i].commodity_id });

                    }
                    var container_detail = 'container5';
                    var title_detail = 'by Commodity FG Prod'
                    await this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }
                this.$store.dispatch('setOverlay', false)
            }
        },
        async onClick3(e){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'periode': e.dataPoint.label,
                'tahun': e.dataPoint.label.substr(0, 4),
                'bulan': e.dataPoint.label.substr(4, 6),
                'brand': this.brand ? this.brand : '',
                'commodity_id': this.commodity ? this.commodity : '',
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
            }

            const respData = await backendApi.fetchCore('/v2/sm/analysis/sc-prod/getScShipmentAnalysisDetailbyProd', reqBody, false, false, false)

            if (respData.status === 200) {
                var param = e.dataPoint.label;
                var label_detail = "Periode: "+param;
                this.showDataDetail = false

                for (var i = 0; i < respData.data.qWidth.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qWidth.length; i++) {

                        dt_detail.push({  label: respData.data.qWidth[i].width,  y: parseInt(respData.data.qWidth[i].total_wgt_awal), legendText: respData.data.qWidth[i].width });

                    }
                    var container_detail = 'container2';
                    var title_detail = 'by Width FG Prod Wgt Awal'
                    await this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qThick.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qThick.length; i++) {

                        dt_detail.push({  label: respData.data.qThick[i].thick,  y: parseInt(respData.data.qThick[i].total_wgt_awal), legendText: respData.data.qThick[i].thick });

                    }
                    var container_detail = 'container3';
                    var title_detail = 'by Thickness FG Prod Wgt Awal'
                    await this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qBrand.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qBrand.length; i++) {

                        dt_detail.push({  label: respData.data.qBrand[i].brand_name,  y: parseInt(respData.data.qBrand[i].total_wgt_awal), legendText: respData.data.qBrand[i].brand_name });

                    }
                    var container_detail = 'container4';
                    var title_detail = 'by Brand FG Prod Wgt Awal'
                    await this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qCommodity.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qCommodity.length; i++) {

                        dt_detail.push({  label: respData.data.qCommodity[i].commodity_id,  y: parseInt(respData.data.qCommodity[i].total_wgt_awal), legendText: respData.data.qCommodity[i].commodity_id });

                    }
                    var container_detail = 'container5';
                    var title_detail = 'by Commodity FG Prod Wgt Awal'
                    await this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                this.$store.dispatch('setOverlay', false)
            }
        },
        getPieChart(dt_detail, container_detail, title_detail, label_detail, destroy = false){
            var pie_chart = new CanvasJS.Chart(container_detail, {
                animationEnabled: true,
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title_detail,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label_detail,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                legend: {
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{

                    type: "pie",
                    percentFormatString: "#0.##",
                    indexLabel: "{label} #percent%",
                    indexLabelFontSize: 12,

                }]
            });

            pie_chart.options.data[0].dataPoints = dt_detail;
            this.showDefaultText(pie_chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");

            if (x.matches) {

                for(var i = 0; i < pie_chart.options.data.length; i++){
                    pie_chart.options.data[i].indexLabelFontSize = 6;
                }
                pie_chart.render();
            }
            
            pie_chart.render();

            if (destroy == true) {
                pie_chart.destroy();
            }
        }
    },
    watch: {
    }
}
</script>
    